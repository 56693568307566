import React, {useEffect, useState} from 'react'
import { withTranslation } from 'react-i18next';
const axios = require('axios')
const Jurisdiction = ({t}) => {
    const [country, setCountry] = useState(null);
    const scopedCountries = [
        "Afghanistan", "Azerbaijan", "Bosnia and Herzegovina", "Myanmar", "Canada",
        "Congo", "Cuba", "Ethiopia", "Guyana", "Haiti", "Hong Kong", "Iran, Islamic Republic of", "Iraq", "Israel",
        "Japan", "Lebanon", "Malaysia", "Mali", "Korea, Democratic People's Republic of", "Singapore", "Sri Lanka", "Somalia",
        "Sudan", "Trinidad and Tobago", "Tunisia", "Venezuela", "United States of America", "United Arab Emirates"
    ];
    const euCountries = [
        "Austria", "Belgium", "Bulgaria", "Croatia", "Cyprus",
        "Czech", "Denmark", "Estonia", "Finland", "France", "Germany", "Greece", "Hungary", "Ireland", "Italy", "Latvia", "Lithuania", "Luxembourg", "Malta", "Netherlands", "Poland", "Portugal", "Romania",
        "Slovakia", "Slovenia", "Spain", "Sweden"
    ];
    useEffect(() => {
        // axios.get("./api/v2/location")
        const getCountry = async() => {
            const location = await axios.get("http://localhost:3000/api/v2/location");
            if (location.data.code === 0) {
                setCountry(location.data.data.country_name);
            }
        };
        getCountry();
    }, []);
    return (
        <div>
            {
                scopedCountries.includes(country) && <p className="p_jurisdiction">{t('scopedCountries_west').replace('{this.state.country}', country)}</p>
            }
            {
                euCountries.includes(country) && <p className="p_jurisdiction">{t('scopedCountries_eu').replace('{this.state.country}', country)}</p>
            }
            {
                country =='Australia' && <p className="p_jurisdiction">{t('countries_australia')}</p>
            }
            {
                country == 'United Kingdom of Great Britain and Northern Ireland' && <p className="p_jurisdiction">{t('countries_uk')}</p>
            }

        </div>
    )
}
export default withTranslation()(Jurisdiction);
