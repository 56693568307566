import React from 'react';
import EmailTemplate from './components/emailTemplate';
import MainComponent from './components/mainComponent';
import Mask from './components/mask';
import Page404 from './components/page404';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';

// 路由拦截，如果微信或QQ打开，则跳转至遮罩页面
const PrivateRoute = ({component: Component, showMask, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            showMask ?
                (
                    <Mask/>
                )
                : (
                    <Component {...props} />
                )
        }
    />
);

const App = () => {
    // 判断是否qq或者微信打开
    const ua = navigator.userAgent.toLowerCase();
    const showMask=!!/micromessenger/i.test(ua) || !!(/ qq/i.test(ua) && !/ qqbrowser/i.test(ua));
    return (
        <Router>
            <Switch>
                <Route path="/mask" component={Mask}/>
                <PrivateRoute path="/disclosure/email" component={EmailTemplate} showMask={showMask}/>
                <PrivateRoute exact path="/en" component={MainComponent} showMask={showMask}/>
                <PrivateRoute exact path="/zh" component={MainComponent} showMask={showMask}/>
                <PrivateRoute exact path="/tc" component={MainComponent} showMask={showMask}/>
                <Route exact path="/">
                    <Redirect to="/en" />
                </Route>
                <Route path="*" component={Page404}/>
            </Switch>
        </Router>
    );
}

export default App;
