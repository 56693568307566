import React, { useEffect, useState } from "react";
import logo from "../../assets/Logo.svg";
import logoLight from "../../assets/Logo-light.svg";
import { withTranslation } from 'react-i18next';
import LangChange from "./langChange";

const Navbar = ({ i18n, t, light }) => {
  const [isFloating, setIsFloating] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('transparent');
  const [language, setLanguage] = useState('en');
  const changeBG = () => {
    if (window.scrollY >= (light ? 100 : 642)) {
      setIsFloating(true);
      light ? setBackgroundColor('white') : setBackgroundColor('black');
    } else {
      setIsFloating(false);
      setBackgroundColor('transparent');
    }
  };

  useEffect(() => {
    const matches = window.location.href.match(/(\/(en|zh|tc)$)|(\/(en|zh|tc)\/)/);
    if (matches && matches.length > 0) setLanguage(matches[0].replace('/', ''))
    console.log('isLight', light)
    document.addEventListener("scroll", changeBG);
    return () => {
      document.removeEventListener("scroll", changeBG);
    };
  }, []);

  return (
    <nav
      className={light ? "navbar navbar-light" : "navbar"}
      style={{backgroundColor: backgroundColor, marginTop: (isFloating ? "0px" : '36px')}}
    >
      <div className="container">
        <div
          className="navbar-brand"
          style={
            isFloating
              ? { width: "163px", height: "36px", paddingTop: "20px" }
              : { width: "163px", height: "36px" }
          }
        >
          <img className="responsive-img" src={light ? logoLight : logo} alt="doo holding logo" />
        </div>
        <ul className="navbar-nav">
          <li className="navbar-item">
            <a className="navbar-link" href={`/${language}#home`}>
            {t('home')}
            </a>
          </li>
          <li className="navbar-item">
            <a className="navbar-link" href={`/${language}#brands`}>
            {t('brands')}
            </a>
          </li>
          <li className="navbar-item">
            <a className="navbar-link" href={`/${language}#core-values`}>
            {t('values')}
            </a>
          </li>
          <li className="navbar-item">
            <a className="navbar-link" href={`/${language}#global-offices`}>
            {t('offices')}
            </a>
          </li>
          <li className="navbar-item">
            <a className="navbar-link" href={`/${language}#contact-us`}>
            {t('contact_us')}
            </a>
          </li>
          <LangChange currentLang={ i18n.language } light={light} />
        </ul>
      </div>
    </nav>
  );
};

export default withTranslation()(Navbar);
