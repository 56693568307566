import React from "react";
import { withTranslation } from "react-i18next";


const DooEntities = ({ t }) => {


  return (

    <div>
      <div className="grid-container-2">
        <div className="footer-left">
          <p className="name">{t('brands_elish_elish')}</p>
          <p className="detail">{t('brands_elish_elish_content1')}</p>
          <p className="detail">{t('brands_elish_elish_content2')}<span className="detail-highlight"> {t('brands_elish_elish_content2.1')}</span></p>
          <p className="detail">{t('brands_elish_elish_content3')}</p>
          <p className="website">
            <a href="https://www.doofinancial.com/" target="_blank">www.doofinancial.com </a>
          </p>
          <p className="website">
            <a href="https://www.elish.com/" target="_blank" rel="noopener noreferrer">www.elish.com </a>
          </p>
        </div>

        <div className="footer-right">
          <p className="name">{t('brands_doo_clearing')}</p>
          <p className="detail">{t('brands_doo_clearing_content1')}<span className="detail-highlight"> {t('brands_doo_clearing_content1.1')}</span></p>
          <p className="detail">{t('brands_doo_clearing_content2')}<span className="detail-highlight"> {t('brands_doo_clearing_content2.1')}</span></p>
          <p className="website">
            <a href="http://www.dooclearing.co.uk/">www.dooclearing.co.uk</a>
          </p>
        </div>
      </div>

      <div className="grid-container-2">
        <div className="footer-left">
          <p className="name">{t('brands_australia')}</p>
          <p className="detail">{t('brands_australia_content1')}<span className="detail-highlight"> {t('brands_australia_content1.1')}</span></p>
          <p className="detail">{t('brands_australia_content2')}<span className="detail-highlight"> {t('brands_australia_content2.1')}</span></p>
          <p className="website">
            <br />
            <a href="https://www.doofinancial.com.au/">www.doofinancial.com.au</a>
          </p>
        </div>

        <div className="footer-right">
          <p className="name">{t('brands_doo_wealth_management')}</p>
          <p className="detail">{t('brands_doo_wealth_management_content1')}<span className="detail-highlight"> {t('brands_doo_wealth_management_content1.1')}</span></p>
          <p className="detail">{t('brands_doo_wealth_management_content2')}<span className="detail-highlight"> {t('brands_doo_wealth_management_content2.1')}</span></p>
          <p className="website">
            <br />
            {/* <a href="https://www.dooexchange.com/">www.dooexchange.com</a>*/}
          </p>
        </div>
      </div>

      <div className="grid-container-2">
        <div className="footer-left">
          <p className="name">{t('brands_doo_holdgroup')}</p>
          <p className="detail">{t('brands_doo_holdgroup_content1')}<span className="detail-highlight"> {t('brands_doo_holdgroup_content1.1')}</span></p>
          <p className="detail">{t('brands_doo_holdgroup_content2')}<span className="detail-highlight"> {t('brands_doo_holdgroup_content2.1')}</span></p>
          <p className="website">
            <br />
            <a href="https://www.dooexchange.com/">www.dooexchange.com</a>
          </p>
        </div>

        <div className="footer-right">
          <p className="name">{t('brands_doo_consulting')}</p>
          <p className="detail">{t('brands_doo_consulting_content1')}<span className="detail-highlight"> {t('brands_doo_consulting_content1.1')}</span></p>
          <p className="detail">{t('brands_doo_consulting_content2')}<span className="detail-highlight"> {t('brands_doo_consulting_content2.1')}</span></p>
          <p className="website">
            <br />
            {/* <a href="https://www.doo.tech/">www.dooclearing.co.uk</a> */}
          </p>
        </div>
      </div>

      <div className="grid-container-2">
        <div className="footer-left">
          <p className="name">{t('brands_seychelles')}</p>
          <p className="detail">{t('brands_seychelles_content1')}<span className="detail-highlight"> {t('brands_seychelles_content1.1')}</span></p>
          <p className="detail">{t('brands_seychelles_content2')}<span className="detail-highlight"> {t('brands_seychelles_content2.1')}</span></p>
          <p className="website">
            <br />
            <a href="https://www.dooprime.sc/">www.dooprime.sc</a>
          </p>
        </div>

        <div className="footer-right">
          <p className="name">{t('brands_doo_mauritius')}</p>
          <p className="detail">{t('brands_doo_mauritius_content1')}<span className="detail-highlight"> {t('brands_doo_mauritius_content1.1')}</span></p>
          <p className="detail">{t('brands_doo_mauritius_content2')}<span className="detail-highlight"> {t('brands_doo_mauritius_content2.1')}</span></p>
          <p className="website">
            <br />
            <a href="https://www.dooprime.mu/">www.dooprime.mu </a>
          </p>
        </div>
      </div>

      <div className="grid-container-2 border_bottom_line">
        <div className="footer-left">
          <p className="name">{t('brands_doo_vantau')}</p>
          <p className="detail">{t('brands_doo_vantau_content1')}<span className="detail-highlight"> {t('brands_doo_vantau_content1.1')}</span></p>
          <p className="detail">{t('brands_doo_vantau_content2')}<span className="detail-highlight"> {t('brands_doo_vantau_content2.1')}</span></p>
          <p className="website">
            <br />
            <a href="https://www.dooprime.com/">www.dooprime.com</a>
          </p>
          </div>
      </div>
    </div>


  );
}

export default withTranslation()(DooEntities);