import React from "react";
import { Helmet } from 'react-helmet';
import Navbar from "../navbar";
import Home from "../home";
import Brands from "../brands";
import CoreValues from "../coreValues";
import GlobalOffices from "../globalOffices";
import Contact from "../contact";
import { withTranslation } from 'react-i18next';

const MainComponent = ({ t }) => {
  return (
    
    <div className="app">
      <Helmet>
        <title>{t('seo_title')}</title>
      </Helmet>
      <Navbar />
      <Home />
      <Brands />
      <CoreValues />
      <GlobalOffices />
      <Contact />
    </div>
    
  );
  
};

export default withTranslation()(MainComponent);
