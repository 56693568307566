import React, { useEffect, useState } from "react";
import map from "../../assets/map.svg";
import Jurisdiction from "../contact/jurisdiction";
import { withTranslation } from 'react-i18next';

const mapPoints = [
  {
    place: "London",
    point: [46, 20],
    address:
      "Berkeley Suite, 35 Berkeley Square, Mayfair, London, England, W1J 5BF",
  },
  {
    place: "Hong Kong",
    point: [75, 36],
    address:
      "25/F, Tower B, Billion Centre, No. 1Wang Kwong Road, Kowloon Bay, Hong Kong",
  },
  {
    place: "Taipei",
    point: [78, 33],
    address:
      "No. 409, Section 2, Tiding Boulevard, Neihu District, Taipei City, Taiwan 114",
  },
  {
    place: "Kuala Lumpur",
    point: [73, 55],
    address:
      "Bangsar South City, No.8 Jalan Kerinchi, 59200 Kuala Lumpur, Malaysia",
  },
  {
    place: "Labuan",
    point: [76.5, 57.5],
    address: "Office Suite 1632, Level 16 (A), Main Office Tower, Financial Park Complex Labuan, Jalan Merdeka, 87000 Labuan F.T, Malaysia",
  },
  {
    place: "Singapore",
    point: [74, 61],
    address: "6A Shenton Way, #04-01, Downtown Gallery, Singapore (068815)",
  },
  {
    place: "Ho Chi Minh City",
    point: [75, 50],
    address:
      "Sala City, Ward An Loi Dong, District 2, Ho Chi Minh City, Vietnam",
  },
  {
    place: "Mauritius",
    point: [60, 70],
    address:
      "The Cyberati Lounge, Ground Floor, The Catalyst, Silicon Avenue, 40 Cybercity, 72201 Ebene, Republic of Mauritius",
  },
  {
    place: "Seychelles",
    point: [59, 61],
    address:
      "Room B11, 1st Floor, Providence Complex, Providence, Mahe, Seychelles",
  },
  {
    place: "Vanuatu",
    point: [86, 67],
    address: "Govant Building, Po Box 1276, Port Vila, Vanuatu",
  },
  {
    place: "Sydney",
    point: [85, 79],
    address:"Suite 607 Level 6, 109 Pitt Street, Sydney NSW 2000",
  },
  {
    place: "Dallas",
    point: [19, 30],
    address: "5049 Edwards Ranch Road, Suite 400, Fort Worth, TX 76109, United States.",
  },
  {
    place: "Dubai",
    point: [60, 41],
    address: "Unit 705A, B05, Building BC04, RAKEZ Business Zone - FZ Ras Al Khaimah, United Arab Emirates",
  },
  {
    place: "Bangkok",
    point: [73, 46],
    address: "28th Floor, Rama 9 Road, Huaykwang Sub-district, Huaykwang District, Bangkok",
  },
];

const GlobalOffices = ({t}) => {
  return (
    <section id="global-offices" className="global-offices">
      <div className="container">
        <h2>{t('global_offices_title')}</h2>
        <h3>
          {t('global_offices_header')}
          <Jurisdiction />
          <br></br>
        </h3>
        <div className="hover-text">
          <h5>
          {t('global_offices_content1')}
            <br></br>
            <br></br>
            {t('global_offices_content2')}
          </h5>
          <br></br>
          <h5>
          {t('global_offices_content3')}
          </h5>
        </div>
        <div className="arrow-down"></div>
        <br></br>
        <br></br>

        <div className="map">
          <img
            className="responsive-img"
            src={map}
            alt="global offices around the world"
          />
          {mapPoints.map((mapPoint) => (
            <MapPointer {...mapPoint} key={mapPoint.place} />
          ))}
        </div>
      </div>
    </section>
  );
};

const MapPointer = ({ place, point, address }) => {
  const style = {
    left: `${point[0]}%`,
    top: `${point[1]}%`,
  };

  return (
    <div className="point" style={style}>
      <span className="red-dot" style={style}>
        <p className="place">{place}</p>
        <div className="tooltip">
          {address.split("\n").map((p) => (
            <p className="tooltip-wrap" key={p}>
              {p}
            </p>
          ))}
        </div>
      </span>
    </div>
  );
};

export default withTranslation()(GlobalOffices);
