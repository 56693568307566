import React from 'react';

const Mask = () => {
    return (
        <div id="mask" style={{color:'#ffffff'}}>
            <div className="wei-mask">
                <img src="https://cdn.dootech.io/doo-tech/images/doo_tech_MaskTip.png" width="80%" alt="tip"/>
            </div>
        </div>
    );
}
export default Mask;
