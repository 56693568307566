import React from "react";
import img1 from "../../assets/3.png";
import img2 from "../../assets/4.png";
import img3 from "../../assets/5.png";
import { withTranslation } from 'react-i18next';

const CoreValues = ({t}) => (
  <section id="core-values" className="core-values">
    <div className="container">
      <h2>{t('our_beliefs_title')}</h2>
      <div className="grid-container-2 outer-grid">
        <div className="grid-container-2 inner-grid-left">
          <figure>
            <img
              style={{ width: "270px", height: "504px", objectFit: "cover" }}
              src={img1}
              alt=""
            />
          </figure>
          <div className="grid-content">
            <h3>{t('our_beliefs_values1')}</h3>
            <div className="inner-grid-content">
              <div>
                <h4>{t('our_beliefs_values2')}</h4>
                <p>
                {t('our_beliefs_values3')}
                </p>
              </div>
              <div>
                <h4 style={{ marginTop: "40px" }}>
                {t('our_beliefs_integrity1')}
                </h4>
                <p>
                {t('our_beliefs_integrity2')}
                </p>
              </div>
              <div>
                <h4 style={{ marginTop: "40px" }}>
                {t('our_beliefs_csr1')}
                </h4>
                <p>
                {t('our_beliefs_csr2')}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="inner-grid-right">
          <div className="grid-container-2 grid-content-top">
            <figure>
              <img src={img2} alt="" />
            </figure>
            <div className="grid-content">
              <h3>{t('our_beliefs_mission1')}</h3>
              <p>
              {t('our_beliefs_mission2')}
              </p>
            </div>
          </div>
          <div className="grid-container-2 grid-content-bottom">
            <figure>
              <img src={img3} alt="" />
            </figure>
            <div className="grid-content">
              <h3>{t('our_beliefs_vision1')}</h3>
              <p>{t('our_beliefs_vision2')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default withTranslation()(CoreValues);
