import React from "react";
import { Link } from "react-router-dom";

const LangChange = ({ currentLang, light }) => {
    const getColor = (lang) => {
        return { color: lang == currentLang ? (light === true ? '#e03323' : '#ffffff') : 'grey' };
    }

    return (
        <li className="navbar-item">
            <a href="/zh" style={getColor('zh')}>简</a> /
            <a href="/tc" style={getColor('tc')}>繁</a> /
            <a href="/en" style={getColor('en')}>EN</a>
        </li>
    );
};

export default LangChange;