import React from 'react'
import DooPrimeLogo from '../../assets/our-brands-doo-prime.svg'
import DooClearingLogo from '../../assets/DooClearing.svg'
import DooClearingColorLogo from '../../assets/DooClearingColor.svg'
import DooTechLogo from '../../assets/DooTechColor.svg'
import DooExchangeLogo from '../../assets/DooExchangeColor.svg'
import PeterElishLogo from '../../assets/our-brands-peterelish.svg'
import { withTranslation } from 'react-i18next';


const items = [
    {
        logoSrc: DooPrimeLogo,
        text: "With years of industry experience and world-class technical support, Doo Prime provides retail customers and professional traders with the best liquidity solutions for financial markets, allowing all of our investors to enjoy the fastest rates at the lowest market rates.",
        link: "https://www.dooprime.com/",
        readmore: "read more"
    },
    {
        logoSrc: DooClearingColorLogo,
        text: "Doo Clearing, a UK-based Spot Forex and CFDs liquidity provider, provides a reliable and advanced system that allows the clients to access global liquidity under a single connection. Doo Clearing offers a comprehensive liquidity system that includes liquidity access, quotation aggregation, liquidity distribution, clearing account, risk management, reporting, and system health-level checking.",
        link: "https://www.dooclearing.co.uk/",
        readmore: "read more"
    },
    {
        logoSrc: DooTechLogo,
        text: "Cutting-edge technology with an ultra-low latency trading environment and multi-asset arrangement, Doo Tech can advance your workflow management and efficiency dramatically. Outstanding customer services consistently executes customized support, Doo Tech brings your business to the next level.",
        link: "https://doo.tech/",
        readmore: "read more"
    },
    {
        logoSrc: PeterElishLogo,
        text: "Peter Elish Investments Securities, a United States (US) Securities Broker-Dealer provides a wide range of investment banking services with over 30 years of industry experience.",
        link: "http://elish.com/",
        readmore: "read more"
    },
    {
        logoSrc: DooExchangeLogo,
        text: "Doo Exchange is a foreign currency exchange platform that is dedicated to overcome geographical boundaries regarding currencies by providing a full range of money exchange services to corporate and retail clients.",
        link: "http://www.dooexchange.com/",
        readmore: "read more"
    }
]

const Brands = ({t}) => (
    <section id="brands" className="brands">
        <div className="container">
            <h2>{t('doo_banner_ourbrands')}</h2>
            <div className="grid-container-5">
            {
                    items.map((item, index)=> {
                        item.text = t(`doo_banner_${index}`)
                        
                    })
                }
                 {
                    items.map((item, index)=> {
                        item.readmore = t(`read_more`)
                        
                    })
                }
                {
                    items.map((item,index) =>
                    <Brand {...item} key={index}/>
                    )
                }
                
            </div>
        </div>
    </section>
)

class Brand extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            offsetHeight: 0,
            isHover: false
        }
    }


    componentDidMount() {
        this.handleRender()
        window.addEventListener("resize", this.resize)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize)
    }

    handleMouseOver = e => {
        this.setState({ isHover: true })
    }

    handleMouseOut = e => {
        this.setState({ isHover: false })
    }

    resize = () => {
        this.handleRender()
    }

    handleRender() {
        setTimeout(() => {
            this.setState({
                offsetHeight: this.divElement.clientHeight - 242 - this.imgElement.clientHeight - 30
            })
        }, 300)
    }

    render() {
        const {
            logoSrc,
            overlaySrc,
            text,
            link,
            readmore
        } = this.props
        const offsetHeight =
            this.state.isHover ?
                0 :
                this.state.offsetHeight
        const style = {
            transform: `translate(0, ${offsetHeight}px`
        }
        return (
            <div className="brand" ref={(divElement) => { this.divElement = divElement }} onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut}>
                <div className="overlay" />
                <div className="brand-item" style={style}>
                    <figure ref={(imgElement) => { this.imgElement = imgElement }}><img src={logoSrc} className="responsive-img" alt="" /></figure>
                    <p>{text}</p>
                    <hr size='1' />
                    <a href={link} target="_blank" rel="noopener noreferrer">{readmore} &gt;</a>
                </div>
            </div>
            
        )
    }
}

export default withTranslation()(Brands);
