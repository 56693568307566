import i18n from "i18next";
import { initReactI18next  } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en.json';
import translationTC from './locales/tc.json';
import translationZH from './locales/zh.json';


// the translations
const resources = {
  en: {
    translation: translationEN
  },
  tc: {
    translation: translationTC
  },
  zh: {
    translation: translationZH
  }
};


i18n
  .use(LanguageDetector)
  .use(initReactI18next ) // passes i18n down to react-i18next
  .init({
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
    },
    fallbackLng:'en',
    resources,
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
