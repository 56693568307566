import React from "react";
import { withTranslation } from 'react-i18next';


const Home = ({ t }) => (
  <section id="home" className="home">
    <div className="container">
      <div className="wrapper">
        <p>{t('title_label_1')}</p>
        <h1>{t('title_label_2')} <br/> {t('title_label_2.1')} </h1>
        <h2>
        {t('title_label_3')}
        </h2>
      </div>
    </div>
  </section>
);

export default withTranslation()(Home);
