import React from "react";
import Collapsible from 'react-collapsible';
import DooEntities from "./dooEntities";
import { withTranslation } from "react-i18next";

const Tester = ({ t }) => {
    return (
        <div className="container">
            <Collapsible tabIndex={0} open trigger={t('doo_entity_notice')} className="entity_style margin_table">
                <DooEntities />
            </Collapsible>
        </div>
    );
}
export default withTranslation()(Tester);