import React from "react";
import Navbar from "../navbar";
import Contact from "../contact";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import icon from '../../assets/icon-404.svg';

const Page404 = ({ t }) => {
    return (
        <div className="pageError">
            <Navbar light={true} />
            <div className="container">
                <div className="pageError-wrapper">
                    <div className="pageError-image-wrapper">
                        <img className="pageError-image" src={icon} />
                    </div>
                    <div className="pageError-content">
                        <h2 className="pageError-text" dangerouslySetInnerHTML={{__html: t('page_404_content')}}></h2>
                        <Link to="/" className="pageError-link">{t('page_404_back_to_homepage')}</Link>
                    </div>
                </div>
            </div>
            <Contact />
        </div>
    )
}

export default withTranslation()(Page404);