import React from "react";

const EmailTemplate = () => {
  return (
    <table
      border={0}
      cellpadding={0}
      cellspacing={0}
      style={{
        overflow: "hidden",
        fontSize: "14px",
        lineHeight: "23px",
        padding: "20px 5px",
        color: "#595857",
      }}
    >
      <tr>
        <td colspan={2}>
          <span
            style={{
              color: "#B5B5B6",
              lineHeight: "18px",
              fontSize: "12px",
              fontFamily: "Verdana, sans-serif",
            }}
          >
            This email along with its information and/or attachment(s) is confidential and  contains proprietary, business-confidential and/or privileged material. If you are not the intended recipient of this email, you are hereby notified that (a) any use, dissemination, distribution, reproduction, retransmission; and (b) any action taken in reliance; of this email, including information or material, herein is strictly prohibited. If you have received this email in error, please notify us immediately and take all necessary steps to delete it from any transient or permanent storage device or medium without copying or disclosing any information. We reserve our right to take legal actions if necessary. We do all we can to warrant that the information, material and/or attachment(s) herein is free from viruses, bugs or anything with destructive properties. Therefore, we shall not be liable for any direct, indirect, consequential loss or damages from the use of information, material and/or attachment(s) herein.
            <br />
          </span>
        </td>
      </tr>
      <tr>
        <td colspan={2} style={{ padding: "20px 0" }}>
          <span
            style={{
              color: "#B5B5B6",
              lineHeight: "18px",
              fontSize: "12px",
              fontFamily: "Verdana, sans-serif",
            }}
          >
             DOO GROUP © ALL RIGHTS RESERVED

          </span>
        </td>
      </tr>
      <tr>
        <td>
          <img
            src="https://cdn.dootech.io/doo-hk-website/images/Icon_Doo%20Group.png"
            alt="Doo Holding Logo"
            height={30}
          />
        </td>
        <td style={{ width: "310px" }}></td>
      </tr>
    </table>
  );
};

export default EmailTemplate;
