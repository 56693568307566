import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './style.css'
import { withTranslation } from 'react-i18next';


const Disclosure = ({t}) => {

    return (
        <div>
        <Tabs>
            <TabList>
                <Tab>{t('disclosure_label_1')}</Tab>
                <Tab>{t('disclosure_label_2')}</Tab>
                <Tab>{t('disclosure_label_3')}</Tab>
                <Tab>{t('disclosure_label_4')}</Tab>
            </TabList>
            <TabPanel>
                <p className="style1">{t('disclosure_label_1_tab_panel_line_1')} <br/><br/></p>
                <p className="style1">{t('disclosure_label_1_tab_panel_line_2')} <br/><br/></p>
                <p className="style1">{t('disclosure_label_1_tab_panel_line_3')} <br/><br/></p>
                <p className="style1">{t('disclosure_label_1_tab_panel_line_4')} <br/><br/></p>
            </TabPanel>
            <TabPanel>
                <p className="style1">{t('disclosure_label_2_tab_panel_line_1')} <br/><br/></p>
                <p className="style1">{t('disclosure_label_2_tab_panel_line_2')} <br/><br/></p>
                <p className="style1">{t('disclosure_label_2_tab_panel_line_3')} <br/><br/></p>
            </TabPanel>
            <TabPanel>
                <p className="style1">{t('disclosure_label_3_tab_panel_line_1')} <br/><br/></p>
                <p className="style1">{t('disclosure_label_3_tab_panel_line_2')} <br/><br/></p>
                <p className="style1">{t('disclosure_label_3_tab_panel_line_3')} <br/><br/></p>
            </TabPanel>
            <TabPanel>
                <p className="style1">{t('disclosure_label_4_tab_panel_line_1')} <br/><br/></p>
                <p className="style1">{t('disclosure_label_4_tab_panel_line_2')} <br/><br/></p>
                <p className="style1">{t('disclosure_label_4_tab_panel_line_3')} <br/><br/></p>
                <p className="style1">{t('disclosure_label_4_tab_panel_line_4')} <br/><br/></p>
            </TabPanel>
        </Tabs>
        </div>
    )
}
export default withTranslation()(Disclosure);
